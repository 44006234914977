import React from 'react';
import Layout from '@components/layout';
import { LANG_SE_SV } from '@/src/common/consts/lang';
import Seo from '@components/seo';
import SalesforceSmsTag from '@components/salesforceSmsTag/SalesforceSmsTag';
import { MARKET_SE } from '@/src/common/consts/market';
import { THEME_LIGHT, THEME_WHITE } from '@/src/common/consts/theme';
import PatientPhoneIllustration from '@assets/patient-phone.svg';

const SalesforceDemoPage = () => (
  <Layout
    dataTheme={THEME_LIGHT}
    headerWitdh="header"
    headerStyle="flex--center"
    lang="se"
    showFooter
  >
    {/* <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} /> */}
    <Seo title="! REFERRAL DEMO !" language="sv" />
    <section data-theme={THEME_LIGHT}>
      <div className="container container--xs flex flex--column flex--center text--center margin-bottom--xxl margin-top--xs" data-theme={THEME_WHITE}>
        <div className="whiteBox" style={{ overflow: 'visible' }}>
          {/* <img src={PatientPhoneIllustration} alt="Patient illustration" height="130px" className="margin-bottom--md" /> */}
          <h2 className="text--lg">Demo</h2>
          <div style={{ minWidth: 400 }}>

            <div style={{ position: 'relative', zIndex: 5 }}>
              <h3 className="margin-bottom--sm margin-top--md">Alla Konto</h3>
              <SalesforceSmsTag hideSmsTag market={MARKET_SE} lang={LANG_SE_SV} placeholder="Ange klinik" showAccounts />
            </div>

            <div style={{ position: 'relative', zIndex: 4 }}>
              <h3 className="margin-bottom--sm margin-top--lg">Alla Konto + Kontakter</h3>
              <SalesforceSmsTag hideSmsTag market={MARKET_SE} lang={LANG_SE_SV} placeholder="Ange klinik/kontakt" showContacts showAccounts />
            </div>

            <div style={{ position: 'relative', zIndex: 3 }}>
              <h3 className="margin-bottom--sm margin-top--lg">Alla Kontakter</h3>
              <SalesforceSmsTag hideSmsTag market={MARKET_SE} lang={LANG_SE_SV} placeholder="Ange kontakt" showContacts />
            </div>

            <div style={{ position: 'relative', zIndex: 2 }}>
              <h3 className="margin-bottom--sm margin-top--lg">Kontakter (Joint Academy)</h3>
              <SalesforceSmsTag hideSmsTag market={MARKET_SE} lang={LANG_SE_SV} placeholder="Ange kontakt" showContacts showJointAcademyOnly />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default SalesforceDemoPage;
